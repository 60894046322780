.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  column-fill: auto;
}

.cardContent {
  width: 600px;
  min-width: 300px;
  align-content: center;
  margin-top: 64px;
}

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.saveButton {
  font-size: 12px;
  margin-top: 32px;
  width: 200px;
  height: 35px;
}

.header {
  text-align: left;
  padding: 25px 0px 20px 20px;
  background-color: #f7f6f6;
}

.mainHeaderText {
  font-weight: 900;
  text-align: left;
  font-size: 22px;
  color: #574a97;
  padding-top: 10px;
}

.cardHeaderText {
  font-weight: 900;
  text-align: left;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.centerContainer {
  justify-content: center;
  display: flex;
}

.columnCard {
  border: 1px solid #e5e5e5;
  padding: 20px;
  margin-bottom: 22px;
  text-align: left;
}

.elementsContainer {
  margin-bottom: 20px;
}
