.container {
  text-align: left;
  margin-top: 22px;
}

.title {
  font-family: Avenir;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  background-color: var(--grayedWhite);
  padding: 10px 10px 15px 20px;
  font-weight: 300;
}

.subTitle {
  background: #f6f6f6;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  padding: 20px;
}
