.container {
  padding-top: 10px;
}

.title {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
}

.noData {
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  text-align: left;
}

.chartUnits {
  font-weight: 900;
  padding-left: 10px;
}
