.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  column-fill: auto;
}

.linkButton {
  color: black;
  font-size: 11px;
  letter-spacing: 0.44px;
  padding: 26px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  text-decoration-color: var(--black);
}

.linkButton:hover {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemContainer {
  width: 600px;
  min-width: 300px;
  border: solid 0.5px #c7c7cc;
  border-radius: 8px;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 15px;
  margin: 0px 19px;
}

.itemDivider {
  height: 16px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 10px;
}

.subTotalContainer {
  width: 600px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.name {
  margin: 0px;
  font-size: 20px;
  letter-spacing: 0.23;
}

.price {
  margin: 0px;
  font-size: 18px;
  letter-spacing: 0.1;
  font-weight: 900;
}

.divider {
  border-top: solid 1px #d1d1d6;
}

.blackDividerTop {
  margin-top: 40px;
  width: 600px;
  min-width: 300px;
  border-top: solid 1px #000;
}

.blackDividerBottom {
  margin-top: 20px;
  width: 600px;
  min-width: 300px;
  border-top: solid 1px #000;
}

.packageLabel {
  font-size: 12px;
  letter-spacing: 0.5 !important;
  font-weight: 900;
  margin: 0px !important;
}

.removeIconContainer {
  padding: 10px 0px;
  cursor: pointer;
}

.removeIcon {
  width: 24px;
  height: 24px;
}

.subTotalTitle {
  font-size: 18px;
  letter-spacing: 0.33px;
  margin: 0px;
}

.priceTitle {
  font-size: 22px;
  letter-spacing: 0.4px;
  font-weight: 900;
}

.checkoutButton {
  width: 200px;
  height: 40px;
  border-radius: 30px;
  margin-left: 10px;
}

.cardsSpacer {
  height: 30px;
}

.cards {
  width: 200px;
  height: 40px;
}

/* Cart page styling */
.cartItemContainer {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
  display: flex;
  border-bottom: solid #f7f6f6;
  align-items: center;
}

.cartItemHeader {
  width: 100%;
  padding: 10px 0px 10px 0px;
  flex-direction: row;
  display: flex;
  border-bottom: solid #f7f6f6;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  object-fit: contain;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  min-height: 77px;
  background-color: #f7f6f6;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 6em 15px 0px;
}

.header {
  text-align: left;
  padding: 25px 0px 20px 20px;
  background-color: #f7f6f6;
}

.mainHeaderText {
  font-weight: 900;
  text-align: left;
  font-size: 22px;
  color: #574a97;
  padding-top: 10px;
}

.headerText {
  font-size: 12px;
  font-weight: 500;
  color: #a5a5a5;
}

.productRow {
  justify-content: center;
  text-align: center;
  padding-bottom: 60px;
}

.pricingText {
  margin-top: 5px;
  font-size: 14px;
}

/* Cart item styles */
.removeButtonColumn {
  width: 12%;
}

.nameColumn {
  width: 68%;
  flex-direction: column;
  text-align: left;
}

.priceColumn {
  width: 20%;
  text-align: left;
}
