.container {
  width: 100%;
}

.category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 10px 20px;
  background-color: var(--grayedWhite);
}

.category h2 {
  margin-bottom: 0;
}

.categoryInfo {
  background-color: var(--white);
  padding: 10px 20px;
  border-bottom-width: 1px;
  border-bottom-color: var(--grayedWhite);
  border-bottom-style: solid;
}

.categoryImage {
  width: 45px;
  height: 45px;
}

.subtitle,
.summary {
  text-align: left;
  margin: 10px;
}

.summaryContainer {
  background-color: white;
  padding: 10px;
}
