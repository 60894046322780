.container {
  display: flex;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--darkWhite);
  background-color: var(--white);
  padding: 15px;
  overflow: hidden;
  margin: 0 40px 0 30px;
}

.plusButton {
  border: 0;
}

.inputContainer {
  width: 100%;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 15px;
  border: 0.5px solid var(--silver);
  background-color: var(--alabaster);
}

.input {
  width: 100%;
  max-height: 74px;
  padding: 8px 12px;
  overflow: hidden;
  overflow-y: auto;
  outline: none;
  overflow-wrap: break-word;
  margin-bottom: 4px;
}

.input::-webkit-scrollbar {
  width: 6px;
}

.input:empty:not(:focus)::before {
  content: attr(placeholder);
  color: var(--dustyGray);
}

.sendButton:focus {
  outline: 0;
}

@media only screen and (max-width: 1304px) {
  .container {
    margin: 0 20px 0 10px;
  }
}
