.container {
  width: 100%;
}

.panelsContainer {
  display: flex;
  flex: 1;
  padding: 17px 20px 12px 20px;
  overflow: hidden;
  background: var(--white);
  height: calc(100% - 73px);
}

.contactListContainer {
  display: flex;
  width: 316px;
  height: 100%;
  overflow: hidden;
  border-right: solid 1px #dddddd;
}

.conversationContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
}

.conversationInfoContainer {
  font-family: Avenir;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  height: 100%;
  overflow: auto;
  margin: 0 30px;
  justify-content: center;
}

.composerContainer {
  display: flex;
  margin-top: 6px;
  overflow: hidden;
}

.composerMsgContainer {
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  margin: 0 37px;
}

.userProfileContainer {
  display: flex;
  width: 320px;
  height: 100%;
  overflow: auto;
}

.composerMessage {
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #fe3824;
}

@media only screen and (max-width: 1304px) {
  .contactListContainer {
    width: 255px;
  }

  .userProfileContainer {
    width: 300px;
  }
}
