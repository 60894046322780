.container {
  text-align: left;
  /* padding: 0 15px; */
}

.questions {
  margin-bottom: 13px;
}

.answer {
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 20px;
}

.answers {
  padding-left: 0;
  margin-left: 35px;
}

.question {
  font-family: Avenir;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  margin-bottom: 1px;
  background-color: var(--grayedWhite);
  padding: 10px 10px 15px 20px;
  font-weight: 300;
}
