.container {
  background-color: #f8f8f8;
  width: 100%;
}

/* Header */
.headerRightPanel {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  padding-right: 1rem;
}

.headerTitle {
  color: #1d1d26;
  font-family: Avenir;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-left: 19px;
  text-align: left;
}

.sortByLabel {
  margin-right: 10px;
}

.releaseResultsButton {
  margin-left: 25px;
}

/* Panel left */
.reportNotesHeader {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
}

.reportNotesTitle {
  font-size: 20px;
  margin: 0;
}

.notesContainer {
  margin-top: 20px;
}

.reportNotes {
  padding: 20px;
}

.leftPanel {
  min-width: 300px;
  max-width: 300px;
}

.rightPanel {
  height: 100%;
  overflow: auto;
}

.messagesContainers {
  cursor: pointer;
  display: block;
}

.messageButton {
  margin: 0 25px;
}

.ctasModal {
  width: 100%;
  flex-direction: column;
}
