.informationList {
  height: 100%;
  width: 25%;
  border-left: solid var(--lightGrey) 1px;
  border-right: solid var(--lightGrey) 1px;
}

.photo {
  width: 75px;
  height: 75px;
  border: solid var(--darkWhite) 2px;
  border-radius: 15%;
}

.providerDetails {
  width: 25%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.providersList {
  width: 25%;
  height: 100%;
  border-left: solid var(--lightGrey) 1px;
  border-right: solid var(--lightGrey) 1px;
}

.inputField div {
  width: 100%;
}

.inputField input {
  width: 100%;
}

.inputPartnerCode {
  display: flex;
  padding: 29px;
  position: relative;
}

.inputPartnerCode div:first-child {
  width: 100%;
}

.submitButton button {
  border-radius: 0px 7px 7px 0px;
}

.checkbox {
  margin-right: 4px;
}

.providerName {
  font-weight: 700;
}

.connectButton {
  margin-top: 10px;
}

.addContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.addIcon {
  width: 15px;
  cursor: pointer;
}
