.main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  height: 100%;
}

.header {
  height: 73px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
}

.pageTitle {
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--black);
}
