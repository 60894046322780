.resultReportLink {
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  margin-top: 15px;
  color: #4183c4;
  opacity: 0.7;
}

.pdfIcon {
  align-self: center;
  margin-left: 5px;
  width: 26px;
  height: 23px;
}
