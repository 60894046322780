.transactionsContainer {
  width: 70%;
  height: 100%;
  overflow-y: auto;
  border-left: solid var(--lightGrey) 1px;
  border-right: solid var(--lightGrey) 1px;
  padding: 25px;
}

.transactionItem {
  text-align: start;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--darkWhite);
}

.transactionItemColumn {
  flex-direction: column;
  width: 40%;
}

.priceColumn {
  flex-direction: column;
  width: 20%;
}

.transactionDate {
  font-size: 12px;
  color: var(--gray);
}

.transactionName {
  font-weight: bold;
  font-size: 17px;
  padding-bottom: 5px;
}

.transactionSubtext {
  font-size: 12px;
}

.transactionPrice {
  font-weight: bold;
  font-size: 18px;
  /* text-align: center;
  padding-top: 20px; */
}

.transactionLink {
  padding-left: 5px;
}
