.chartContainer {
  position: relative;

  /* Need to be this width, otherwise, the container will only resize for bigger sizes */
  width: 0;
  min-width: 600px;
}

.chart {
  background: var(--white);
}

.circle {
  cursor: pointer;
}

.tooltipArrow::before {
  top: 100%;
  left: 50%;
  border: 5px solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #594a9b;
  margin-left: -6px;
}

.oneDimensionChartContainer {
  justify-content: center;
}

.chartRowContainer {
  width: 300px;
  flex-direction: row;
  align-items: center;
}

.normalColor {
  background-color: var(--lightGreen);
}

.abnormalColor {
  background-color: var(--redOrange);
}

.dot {
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background-color: white;
}

.dotNormal {
  border: solid 4px var(--lightGreen);
}

.dotAbnormal {
  border: solid 4px var(--redOrange);
}

.initialRoundBar {
  flex: 1;
  height: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.middleRoundBar {
  flex: 2;
  height: 8px;
}

.lastRoundBar {
  flex: 1;
  height: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.minSpace {
  height: 8px;
  width: 1px;
}
