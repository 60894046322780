.actionMainContainer {
  display: flex;
  direction: row;
  align-items: stretch;
  height: 30px;
  width: 300px;
}
.actionInput {
  max-height: 30px;
  width: 200px;
  border: solid 1px var(--darkWhite);
  outline: none;
  padding-left: 8px;
  padding-bottom: 4px;
}

.actionInput::placeholder {
  font-family: Avenir;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.3);
}
.actionButton {
  display: inline-block;
  height: 100%;
  width: 35px;
  border-radius: 0px 7.3px 7.3px 0px;
  font-size: 12px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  background: transparent;
  color: var(--white);
  background-color: var(--pink);
}

.actionButton:hover:not(.disabled) {
  cursor: pointer;
}

.actionButton:disabled {
  opacity: 0.5;
}
