.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  width: auto;
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--darkWhite);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.footer {
  color: #666666;
  font-size: 12px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.footerLink {
  color: #666666;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  text-decoration-color: var(--black);
}

.signInRightHeaderContainer {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.providerCodeContainer {
  width: 215px;
  height: 30px;
  padding: 8px 0 8px;
  background-color: #efeff2;
  background: #efeff2;
  color: #594a9b;
  font-size: 12px;
  border-radius: 0px;
}

.providerCodeInputFormContainer {
  width: 215px;
  height: 30px;
}

.link {
  color: #594a9b;
  cursor: pointer;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}
