.cardContainer {
  width: 300px;
  height: 160px;
  background: #776fc3;
  border-radius: 24px;
  text-align: left;
  padding: 20px 10px 20px 15px;
  margin: 20px 10px 0px 10px;
  flex-direction: column;
  box-shadow: -10px 20px 15px -10px rgba(0, 0, 0, 0.3);
  position: relative;
}
.title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  margin-top: -15px;
}

.productPrice {
  color: #fff;
  font-weight: 900;
  font-size: 18px;
}

.addToCartIcon {
  background-color: #574a97;
  justify-content: flex-end;
  padding: 10px 15px 10px 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  border-radius: 30px;
  margin: -20px 0px 10px 230px;
  float: right;
  cursor: pointer;
  transition: box-shadow 300ms;
}

.inCartIcon {
  background-color: #d76acd;
  justify-content: flex-end;
  padding: 10px 13px 10px 13px;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  border-radius: 30px;
  margin: -20px 0px 10px 230px;
  float: right;
  cursor: pointer;
  transition: box-shadow 300ms;
}

.addToCartIcon:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.inCartIcon:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.learnMoreLink {
  color: #e7ddf2;
  font-weight: 100;
  font-size: 12px;
  margin-top: -15px;
}
