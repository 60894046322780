.storeItemContainer {
  max-width: 300px;
  margin: 10px auto;
  height: fit-content;
  background-color: white;
  border: 1px solid #c7c7cc;
  border-radius: 6px;
}

.itemHeader {
  position: relative;
}

.coverImage {
  height: auto;
  width: 100%;
}

.packageBannerSolid {
  display: flex;
  height: 22px;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.packageBannerFade {
  display: flex;
  height: 22px;
  position: absolute;
  bottom: 4px;
  right: 0;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.packageTitle {
  color: white;
  margin: 0px;
  padding-left: 16px;
}

.storeMainTitleContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0px 16px;
}

.titleInline {
  display: inline;
  margin: 0;
}

.priceInline {
  display: inline;
  margin: 0;
}

.divider {
  height: 1px;
  margin: 6px 16px 9px 16px;
  border-top: solid 1px #d1d1d6;
}

.storeSpinnerLoading {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.inCartLabelContainer {
  padding: 8px 16px;
}

.inCartLabel {
  color: #d76acd;
}
