.container {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  width: 100%;
}

.panels {
  display: flex;
  height: calc(100% - 148px);
  flex: 1 0;
  margin: 15.3px 10px 0;
  padding: 27.7px 7px 0 30.5px;
  background-color: #f8f8f8;
}
