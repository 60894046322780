.container {
  padding: 20px 20px 20px;
  background: var(--white);
  border-bottom: solid 5px #594a9b;
  border-top: solid 1px #d3d3d3;
  display: flex;
  flex-direction: column;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content {
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #1d1d26;
  text-align: left;
}
