.nextButton {
  margin-top: 10px;
  font-family: Avenir !important;
  font-size: 20px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: -0.35px;
}

.buttonContentRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconStyles {
  margin-left: 8px;
  padding-top: 1px;
  width: 40px;
}
