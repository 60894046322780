.photo {
  width: 75px;
  height: 75px;
  border: solid var(--darkWhite) 2px;
  border-radius: 15%;
}

.providerDetails {
  width: 25%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.providersList {
  width: 25%;
  height: 100%;
  border-left: solid var(--lightGrey) 1px;
  border-right: solid var(--lightGrey) 1px;
}

.providerName {
  font-weight: 700;
}

.disconnectButton {
  color: var(--red);
  border-color: var(--red);
}

.addContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.addIcon {
  width: 15px;
  cursor: pointer;
}

.emptyStateContainer {
  width: 100%;
  height: 100%;
}

.emptyStateContent > h3 {
  margin-top: 35px;
  margin-bottom: 10px;
  font-weight: 900;
}

.emptyStateContent > h4 {
  margin-top: 0;
  margin-bottom: 20px;
}
