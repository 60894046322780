.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--darkPurple);
}

.text {
  margin-top: 10px;
  font-size: 14px;
}
