.main {
  width: 100%;
}

.main form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.header {
  height: 73px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
}

.inputField > input {
  background-color: var(--white);
  font-family: Avenir;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.icon {
  position: absolute;
  top: 8px;
  right: 10px;
}

.formField {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0;
}

.formField:first-child {
  margin-top: 0;
}

.columnFields {
  padding: 25px;
}

.avatar {
  width: 25%;
  border-right: 1px solid var(--lightGrey);
  text-align: left;
  padding: 23px;
}

.editButton {
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;
}

.editButton > div {
  width: 161px;
  height: 22px;
  margin: 13px 9px 7px 0px;
  object-fit: contain;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.editButton > img {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.editSubmit {
  display: flex;
}

.changePassword {
  margin: 38px 122.6px 342.1px 39px;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.99;
  letter-spacing: 0.86px;
  color: #fe3824;
}

.pageTitle {
  font-size: 22px;
  font-family: Avenir;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--black);
}

.avatarTitle {
  height: 36px;
  margin: 0 9px 18px 0;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #000000;
}

.content {
  background-color: var(--white);
  border: solid 1px #dddddd;
  margin: 21px;
  padding: 30px 10px 30px 10px;
}

.warning {
  text-align: left;
  height: 73px;
  margin-top: 5px;
  font-family: Avenir;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;
}

.warningBold {
  font-family: Avenir;
  font-weight: 900;
}

.imageField {
  min-width: 320px;
  text-align: start;
  width: 25%;
  padding: 23px;
  border-right: 1px solid var(--lightGrey);
}

.icon {
  position: absolute;
  top: 8px;
  right: 10px;
  color: var(--purple);
}

.lastNameField {
  display: flex;
  position: relative;
}
.emailField {
  display: flex;
  position: relative;
}

.inputIcon {
  margin-top: 9px;
  margin-bottom: 16px;
}

.columnFields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 30px;
  min-width: 180px;
  padding-top: 20px;
  width: 25%;
}

.dateInputField input {
  border-left: none;
  border-right: none;
  border-top: none;
  height: 42px;
  padding-left: 9px;
  background-color: var(--white);
  color: var(--black);
  border-bottom: solid 1px var(--darkWhite);
}

.selectField {
  margin-top: 10px;
}

.selectField :global(.ui.selection.active.dropdown .menu) {
  border: none;
}

.selectField :global(.ui.selection.dropdown) {
  border: none;
  border-bottom: solid 1px #cccccc;
  border-radius: 0;
  min-height: 35px;
}

.selectField :global(.ui.dropdown:not(.button) > .default.text) {
  color: #8083a0;
}

.changePassword {
  color: var(--red);
  cursor: pointer;
  margin: 0;
  margin-top: 50px;
  margin-left: 5px;
  text-align: start;
}
