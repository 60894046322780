.container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 306px;
  overflow: auto;
}

.container::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.container:hover::-webkit-scrollbar {
  display: block;
}

.search {
  font-size: 12px;
  font-family: Avenir;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 296px;
  margin-bottom: 10px;
}

/* FIXME: This is due to a bug in the search component
  that debounces and changes the context of the
  onSearchChange function handler */
/* Start of: Copied from ui-components */
.search :global .ui.input {
  width: 100%;
}

.search :global(.ui.input > input) {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.search:global.ui.active .ui.input > input {
  border: 0;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.search:global(.ui.search > .results) {
  width: 100%;
  top: 30px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/* End of: Copied from ui-components */

.list {
  padding: 0;
  margin: 0;
}

.titlePartner {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  margin-bottom: 2px;
  text-align: left;
}

.availableTeam {
  font-family: Avenir;
  font-size: 10px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  margin-bottom: 0;
  text-align: left;
}

.itemItem {
  width: 298px;
  margin-bottom: 10px;
  padding-right: 2px;
  list-style: none;
}

@media only screen and (max-width: 1304px) {
  .container {
    width: 240px;
  }
  .search {
    width: 240px;
  }
  .itemItem {
    width: 242px;
  }
}
