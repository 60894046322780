.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.listRow {
  border: solid 1px #e5e5e5;
  display: grid;
  grid-template-columns: 50% 20% 15% 15%;
  height: 84px;
  margin: 22px 20px;
  padding: 17px 20px;
  overflow: hidden;
}

.listRowFinal {
  grid-template-areas:
    'name partner date summary'
    'id  provider status summary';
}

.listRowFinal:hover {
  border: solid 1px #000000;
}

.listRowOrdered:hover {
  cursor: default;
}

.reportName {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  height: 22px;
  line-height: 1.38;
  text-align: left;
}

.reportID {
  color: #000000;
  font-size: 14px;
  height: 22px;
  opacity: 0.6;
  text-align: left;
}

.text {
  color: #000000;
  font-size: 14px;
  line-height: 1.79;
  opacity: 0.8;
  text-align: left;
}

.summary {
  display: flex;
  justify-content: flex-start;
  height: 44px;
  grid-area: summary;
}

.summaryValue {
  align-items: center;
  border-radius: 15px;
  color: #000000;
  display: flex;
  justify-content: center;
  height: 26px;
  margin-right: 6px;
  opacity: 0.8;
  width: 47px;
}

.summaryNormal {
  border: 2px solid var(--viking);
}

.summaryAbnormal {
  border: 2px solid var(--radicalRed);
}
