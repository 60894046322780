.header {
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  height: 73px;
  justify-content: flex-start;
  padding: 22px;
  flex-shrink: 0;
  text-align: left;
}

.title {
  font-family: Avenir;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #1d1d26;
}
