.leftPanel {
  min-width: 300px;
  max-width: 300px;
}

.rightPanel {
  height: 100%;
  overflow: auto;
  border-left: solid 1px #dddddd;
  padding: 0 30px 0 40px;
}

.headerTitle {
  color: #1d1d26;
  font-family: Avenir;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-left: 19px;
  text-align: left;
}

.name {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.43px;
  text-align: left;
}

.footer {
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  margin-left: 20px;
}

.notesContainer {
  margin-top: 20px;
}
