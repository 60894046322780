.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 30px;
  padding-right: 10px;
}

.container::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.container:hover::-webkit-scrollbar {
  display: block;
}

@media only screen and (max-width: 1304px) {
  .container {
    margin: 0 10px;
  }
}
