.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  column-fill: auto;
}

.linkButton {
  color: black;
  font-size: 11px;
  letter-spacing: 0.44px;
  padding: 10px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  text-decoration-color: var(--black);
}

.linkButton:hover {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  color: #776fc3;
}

.extraActionsContainer {
  margin-top: 36px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.itemDivider {
  height: 16px;
}

.blackDividerTop {
  margin: 20px 0px 20px 0px;
  border-top: solid 1px #e5e5e5;
}

.blackDividerBottom {
  margin-top: 20px;
  width: 600px;
  min-width: 300px;
  border-top: solid 1px #000;
}

.itemListContainer {
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.spacer {
  height: 12px;
}

.boldLabel {
  font-size: 17px;
  font-weight: 700;
  margin: 0px;
}

.normalLabel {
  font-size: 17px;
  margin: 0px;
}

.smallLabel {
  font-size: 14px;
  margin: 0px;
}

.checkoutButton {
  margin-top: 0px;
  margin-bottom: 20px;
  width: 320px;
  height: 50px;
  border-radius: 30px;
}

.paymentButtonLabel {
  text-transform: capitalize;
  font-size: 15px;
  color: #d76acd;
}

.paymentTitleCard {
  padding-top: 24px;
  font-size: 12px;
  margin: 0px;
  font-weight: bold;
}

.paymentCardInfo {
  text-transform: uppercase;
  padding-top: 8px;
  font-size: 17px;
  margin: 0px;
  font-weight: bold;
}

.header {
  text-align: left;
  padding: 25px 0px 20px 20px;
  background-color: #f7f6f6;
}

.checkoutContainer {
  display: flex;
  flex-direction: row;
  margin: 10px 15% 0px 15%;
}

.mainHeaderText {
  font-weight: 900;
  text-align: left;
  font-size: 22px;
  color: #574a97;
  padding-top: 10px;
}

.columnContainer {
  width: 600px;
  margin-right: 20px;
  padding: 20px 10px 20px 10px;
  text-align: left;
}

.columnCard {
  border: 1px solid #e5e5e5;
  padding: 20px;
  margin-bottom: 22px;
}

.columnCardTitle {
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 22px;
}

.centerContainer {
  text-align: center;
  margin-top: 10px;
}
