.main {
  display: flex;
  justify-content: center;
}

.form {
  margin-top: 34px;
  width: 30%;
  max-width: 336px;
}
