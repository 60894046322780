.container {
  text-align: left;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--doveGray);
}

.text {
  margin-top: 5px;
  font-family: Courier;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #000000;
}

.title {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #000000;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.icon {
  color: var(--doveGray);
}
