.container {
  height: 22px;
  max-width: 180px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text {
  margin-left: 7px;
  opacity: 0.7;
  font-size: 14px;
  font-weight: normal;
  color: var(--shark);
}
