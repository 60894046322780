.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  column-fill: auto;
}

.formContainer {
  flex: 1;
  display: flex;
  width: 336px;
  align-self: center;
  align-items: stretch;
  margin-top: 34px;
  flex-direction: column;
}

.text {
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
}

.title {
  font-size: 28px;
  line-height: normal;
  letter-spacing: -0.33px;
  text-align: left;
}

.icon {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #594a9b;
}

.formField {
  position: relative;
  display: flex;
}

.description {
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.detail {
  text-align: left;
  font-size: 16px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions > button {
  font-family: Avenir;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #d76acd;
  font-size: 20px;
  line-height: 0.9;
  margin-top: 0;
  letter-spacing: -0.35px;
  padding-left: 0;
  text-transform: none;
}

.actions > button > i {
  margin-left: 0.3rem;
}

.input > input {
  border: none;
  border-bottom: solid 1px #cccccc;
  font-weight: 200;
  font-family: 'Avenir';
  border-radius: 0;
  height: 3rem;
  padding: 0;
  width: 336px;
  outline: 0;
}

.input :global(.ui.selection.active.dropdown .menu) {
  border: none;
}

.input :global(.ui.selection.dropdown) {
  border: none;
  border-bottom: solid 1px #cccccc;
  font-family: 'Avenir';
  font-weight: 200;
  border-radius: 0;
  padding: 9px 0 0;
  min-height: 35px;
}

.input :global(.ui.selection.dropdown) > i {
  display: none;
}

.input :global(.ui.dropdown:not(.button) > .default.text) {
  color: #757575;
}

.input > div {
  height: 3rem;
  display: flex;
  align-items: center;
  margin-top: 0;
}

.input > div > div > img {
  width: 18px;
  height: 18px;
}

.dateField > input {
  font-weight: 300;
}

.verificationCodeInput {
  text-align: left;
}

.verificationCodeInput > input {
  border-bottom: 1px solid #cccccc;
  font-family: Avenir;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.gridRootOffset {
  position: relative;
  top: 30px;
}

.inputField div {
  width: 100%;
}

.inputField input {
  width: 100%;
}

.inputPartnerCode {
  display: flex;
  padding: 29px;
  position: relative;
}

.inputPartnerCode div:first-child {
  width: 100%;
}

.submitButton button {
  border-radius: 0px 7px 7px 0px;
}

.stepNumber {
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: -0.41px;
  color: #007aff;
  padding-top: 10px;
}

.description {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.detail {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  font-size: 16px;
}

.photo {
  border: solid var(--darkWhite) 2px;
}

.providerDetails {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.providerName {
  font-weight: 700;
}

.checkbox {
  margin-right: 4px;
}

.connectButton {
  margin-top: 30px;
}

.hipaaAgreement {
  margin-top: 20px;
}
