.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto !important;
  column-fill: auto;
  margin-bottom: 25px;
}

.contentContainer {
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  /* flex-grow: 1;
  overflow: auto;
  min-height: 2em; */
  padding-left: 20px;
}

.shopNavigationContainer {
  flex-direction: column;
  background-color: rgba(252, 252, 252, 0.974);
  width: 15%;
}

.shopContainer {
  flex-direction: column;
  width: 85%;
  padding-left: 20px;
}

.productsRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
}

.listMenu {
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
  column-fill: auto;
  background-color: #e1e1e2;
}

.listMenuHeader {
  font-weight: 900;
  text-align: left;
  padding: 25px 0px 20px 20px;
  background-color: #fcfcfc;
  font-size: 22px;
  color: #574a97;
}

.storeListContainer {
  width: 100%;
  display: flex;
  background-color: white;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
}

.storeList {
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: masonry;
  height: max-content;
  overflow: auto;
}

.storeLogoContainer {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0px;
}

.errorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0px;
}

.storeLogo {
  width: 170px;
  height: 120px;
}

.summary {
  text-align: start;
  padding: 0px 16px 0px 16px;
}

.actionButtonContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cancelButton {
  display: flex;
  color: #d76acd;
  align-self: flex-start;
  margin-left: 0px;
  padding-left: 16px;
  align-items: center;
}

.plusButtonContainer {
  padding: 8px 16px;
  cursor: pointer;
}

.plusButton {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: #d76acd;
  justify-content: center;
  align-items: center;
}

.plusIcon {
  width: 18px;
  height: 18px;
}

.mainHeaderContainer {
  text-align: left;
  padding: 30px 0px 0px 30px;
}
.mainHeaderText {
  font-weight: 900;
  font-size: 22px;
  color: #574a97;
}

.secondHeaderText {
  font-weight: 900;
  font-size: 20px;
  color: #574a97;
}

.checkoutBar {
  position: absolute;
  bottom: 0;
  background-color: #f7f6f6;
  width: 90%;
  padding: 20px 13% 20px 0px;
  display: flex;
  justify-content: flex-end;
}

.shopMenu {
  font-weight: 500;
  text-align: left;
  background-color: #ececec;
  padding: 10px 0px 10px 20px;
  cursor: pointer;
}

.shopMenu:hover {
  background-color: #e1e1e2;
}

.shopMenuActive {
  font-weight: 800;
  text-align: left;
  padding: 10px 0px 10px 20px;
  cursor: pointer;
  background-color: #e1e1e2;
}
