.container {
  display: flex;
}

.container > p {
  text-align: left;
  margin-left: 21px;
}

.round {
  position: relative;
  margin-top: 3px;
}

.round label {
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #d76acd;
  border-color: #d76acd;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.link {
  color: #000;
  font-weight: 500;
  text-decoration: underline;
}
