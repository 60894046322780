.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--gallery);
  background-color: white;
  padding: 10px 30px 10px 30px;
}

.container:not(.editing) {
  cursor: pointer;
}

.field {
  margin: 0;
  flex: 0 0 200px;
}

.statusIcon:global(i.icon) {
  margin: 0;
}

.value {
  margin-right: 20px;
  color: var(--black);
}

.statusAbnormal .value {
  color: var(--red);
}

.statusBorderline .value {
  color: var(--yellow);
}

.name {
  color: var(--black);
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  flex: 1;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 15px;
  text-align: left;
}

.units {
  flex: 0 0 120px;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.angle {
  font-size: 28px;
  color: var(--alto);
}
