.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto !important;
    column-fill: auto;
    align-items: center;
  }
  
  .container {
    height: 100%;
    border-right: 1px solid var(--lightGrey);
  }
  
  .cardInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
  }
  
  .paymentCardInfo {
    text-transform: uppercase;
    padding-top: 8px;
    font-size: 17px;
    margin: 0px;
    font-weight: bold;
  }
  
  .actionButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .checkoutButton {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 320px;
    height: 50px;
    border-radius: 30px;
  }
  
  .saveButton {
    font-size: 14px;
    margin-top: 32px;
    width: 280px;
    height: 46px;
  }
  
  .paymentButton {
    align-self: flex-start;
  }
  
  .paymentButtonLabel {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 15px;
    color: #d76acd;
  }
  
  .icon {
    color: #ff2d55;
    cursor: pointer;
  }