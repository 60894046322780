.column {
  flex-direction: column;
}

.container {
  width: 100%;
  height: 100%;
}

.leftPanel {
  width: 25%;
  height: 100%;
}

.panelContents {
  padding: 25px;
}

.rightPanel {
  height: 100%;
  width: 75%;
}

@media only screen and (max-width: 768px) {
  .leftPanel {
    width: 100%;
    height: 100%;
  }

  .rightPanel {
    height: 100%;
    width: 100%;
  }
}

.notesContainer {
  margin-top: 20px;
}

.statusAbnormal .value {
  color: var(--red);
}

.statusBorderline .value {
  color: var(--yellow);
}

.name {
  color: var(--black);
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  flex: 1;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 15px;
  text-align: left;
}

.value {
  margin-right: 20px;
  color: var(--black);
}

.angle {
  font-size: 28px;
  color: var(--alto);
}
