.bagFullContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-right: 32px;
  padding-bottom: 8px;
  cursor: pointer;
  margin-left: 12px;
}

.bagIcon {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}
