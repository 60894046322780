.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  column-fill: auto;
}

.bigSpacer {
  height: 45px;
}

.spacer {
  height: 20px;
}

.mainTitle {
  margin: 0px;
  font-size: 24px;
  font-weight: bold;
}

.descriptionLabel {
  margin: 0px;
  font-size: 15px;
}

.linkButton {
  color: black;
  font-size: 15px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  text-decoration-color: var(--black);
}

.linkButton:hover {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  color: #c971c8;
}
