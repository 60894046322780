.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  width: auto;
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--darkWhite);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.11);
}

.contentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentHolder {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: calc(100% - 60px);
}

.content {
  display: flex;
  width: 100%;
  overflow: auto;
}

.projectsDropdown {
  margin-right: 30px;
}

.switchPartnerModal {
  width: 400px;
  flex-direction: column;
}

.savePartnerButton {
  margin-top: 10px;
  width: 120px;
  align-self: flex-end;
}

.providerCodeContainer {
  width: 215px;
  height: 30px;
  padding: 8px 0 8px;
  background-color: #efeff2;
  background: #efeff2;
  color: #594a9b;
  font-size: 12px;
  border-radius: 0px;
}

.link {
  color: #594a9b;
  cursor: pointer;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}
