.container {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  border-radius: 4px;
  border: 1px solid var(--darkWhite);
  background-color: var(--white);
  color: var(--black);
}

.containerImage {
  display: flex;
  position: relative;
}

.imageMain {
  width: 100%;
  height: 182px;
}

.name {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  letter-spacing: -0.38px;
  margin: 20px 0 0 0;
  text-align: left;
}

.subtitle {
  font-family: Avenir;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
}

.description {
  margin: 10px 0;
  height: 170px;
  overflow: auto;
}

.containerInfo {
  padding: 27px 15px;
}

.revoked {
  background: #f5f5f5;
  color: #fe3824;
}

.image {
  bottom: -20px;
  border: 2px solid #fff;
  border-radius: 110px;
  left: 21px;
  position: absolute;
  width: 50px;
}

.imageMain {
  width: 100%;
}

.containerFooter {
  position: absolute;
  bottom: 20px;
  margin: auto;
  min-width: 320px;
}

.nameInitials {
  margin: 15px 0 0 15px;
}

@media only screen and (max-width: 1304px) {
  .container {
    min-width: 300px;
    width: 300px;
  }
  .containerFooter {
    min-width: 300px;
    width: 300px;
  }
  .description {
    margin: 10px 0;
    height: 150px;
    overflow: auto;
  }
}
