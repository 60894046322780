.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  column-fill: auto;
}

.inputIcon {
  margin-bottom: 16px;
}

.formContainer {
  flex: 1;
  display: flex;
  width: 336px;
  align-self: center;
  align-items: stretch;
  margin-top: 44px;
  flex-direction: column;
}

.subtitleContainer {
  align-self: flex-start;
}

.signInTitle {
  font-size: 28px;
  font-weight: 900;
  align-self: flex-start;
}

.createAccountLink {
  color: var(--black);
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: initial;
  text-decoration-color: var(--black);
}

.actionContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.forgotPasswordLink {
  color: #594a9b;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.33;
}
