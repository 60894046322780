.container {
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  background-color: #fbeae8;
  padding: 25px 23px 25px 20px;
  margin-bottom: 16px;
  text-align: left;
  border-bottom: solid 5px #fe3824;
}

.footer {
  padding-top: 10px;
  font-family: Avenir;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: normal;
  color: var(--black);
}
