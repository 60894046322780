.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  column-fill: auto;
}

.passwordStepContainer {
  max-width: 378px;
}

.cancelButton {
  display: flex;
  align-items: center;
  width: auto;
}

.avatar {
  min-width: 320px;
  width: 25%;
  border-right: 1px solid var(--lightGrey);
  text-align: left;
  padding: 23px;
}

.stepNumber {
  color: #007aff;
  text-align: left;
}

.formContainer {
  flex: 1;
  display: flex;
  width: 336px;
  align-self: center;
  align-items: stretch;
  margin-top: 34px;
  flex-direction: column;
}

.text {
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
}

.title {
  font-size: 28px;
  line-height: normal;
  letter-spacing: -0.33px;
  text-align: left;
}

.description {
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.detail {
  text-align: left;
  font-size: 16px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions > button {
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  color: #d76acd;
  font-size: 20px;
  line-height: 0.9;
  letter-spacing: -0.35px;
  padding-left: 0;
  text-transform: none;
}

.input > input {
  border: none;
  border-bottom: solid 1px #cccccc;
  border-radius: 0;
  height: 40px;
  padding: 0;
  width: 336px;
}

.input :global(.ui.selection.active.dropdown .menu) {
  border: none;
}

.input :global(.ui.selection.dropdown) {
  border: none;
  border-bottom: solid 1px #cccccc;
  border-radius: 0;
  padding: 9px 0 0;
  min-height: 35px;
}

.input :global(.ui.dropdown:not(.button) > .default.text) {
  color: #8083a0;
}

.verificationCodeInput {
  text-align: left;
}

.verificationCodeInput > input {
  border-bottom: 1px solid #cccccc;
  font-family: Avenir;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.envelopImg {
  width: 25px;
  height: 25px;
  color: var(--purple);
}

.profileImg {
  width: 17x;
  height: 17px;
}

.paddedBottom {
  padding-bottom: 10px;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  height: 100%;
}

.header {
  height: 73px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
}

.editButton {
  display: flex;
  align-items: center;
  width: auto;
  /* cursor: pointer; */
}

.pageTitle {
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--black);
}

.avatarTitle {
  height: 36px;
  margin: 0 9px 18px 0;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #000000;
}

.content {
  background-color: var(--white);
  border-color: var(--lightGrey);
  border-style: solid;
  border-width: 1px;
  margin: 21px;
  padding: 30px 10px 30px 10px;
}
.imageField {
  width: 25%;
  border-right: 1px solid var(--lightGrey);
}

.icon {
  position: absolute;
  top: 8px;
  right: 10px;
}

.formField {
  display: flex;
  position: relative;
}
.lastNameField {
  display: flex;
  position: relative;
}
.emailField {
  display: flex;
  position: relative;
}

.inputIcon {
  margin-bottom: 16px;
}

.columnFields {
  margin-left: 30px;
}

.date input {
  border-left: none;
  border-right: none;
  border-top: none;
  height: 42px;
  padding-left: 9px;
}

.selectField {
  border-left: none;
}

.form {
  padding: 20px;
}

.passwordStrengthNote {
  font-weight: 700;
}

.marginTop30 {
  margin-top: 30px;
}
