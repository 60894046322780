.Panel {
  width: 100%;
  background-color: var(--white);
  padding-bottom: 16px;
}

.PanelHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  padding: 10px 20px;
  color: var(--black);
  background-color: var(--grayedWhite);
}

.PanelHeader h1 {
  margin: 0;
}

.PanelBody {
  min-height: 64px;
}
